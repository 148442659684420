import React, { Component } from 'react';
import styles from './notFoundImage.module.css';
import notFound1 from 'utils/images/not_found_404_1.svg';
import notFound2 from 'utils/images/not_found_404_2.svg';

export default class NotFoundImage extends Component {
  state = {
    image: null
  }

  componentDidMount() {
    this.setState({ image: Math.round(Math.random()) ? notFound1 : notFound2 })
  }

  render() {
    const { image } = this.state;
    return (
      <>
        {image && (
          <div>
            <img src={image} alt="not found" className={styles.image} />
          </div>
        )}
      </>
    )
  }
}
